<template lang="html">
    <section class="reports">
        <section class="container">
            <template v-if="coverages">
                <section class="container-grid">
                    <div class="grid-big">
                        <template v-for="(coverage, key, index) in coverages" :key="key">
                            <reportFeatured v-if="index == 0" :dataContent="coverage"></reportFeatured>
                        </template>
                    </div>
                   
                    <div class="grid-small" v-if="Object.keys(coverages).length > 1">
                        <template v-for="(coverage, key, index) in coverages" :key="key">
                            <newVerticalImg v-if="index == 1" :dataContent="coverage"></newVerticalImg>
                        </template>
                    </div>
                </section>
                <section class="grid-3" v-if="Object.keys(coverages).length > 2">
                    <template v-for="(coverage, key, index) in coverages" :key="key">
                        <newVerticalImg v-if="index > 1" :dataContent="coverage"></newVerticalImg>
                    </template>
                </section>
                <paginator :pageActual="page" :totalItems="CoveragesMaxItems" @clicked="changePages" @changed="changePages"></paginator>
            </template>
            <div v-else class="container-noResult">
                <p class="title-dest-underline">No hay resultados</p>
            </div>
        </section>
    </section>
</template>

<script lang="js">
import {
    mapGetters,
    mapActions,
    mapMutations
} from 'vuex';
import store from '@/store';
import reportFeatured from "@/components/reports/report-featured.vue";
import newVerticalImg from "@/components/news/new-featured-vertical-img.vue";
import paginator from "@/components/paginator.vue";
import utils from "@/utils/utils.js";
export default {
    name: 'reports',
    components: {
        reportFeatured,
        newVerticalImg,
        paginator
    },
    props: ['filterTime'],
    methods: {
        ...mapActions({
            loadCoverages: 'contents/loadCoverages'
        }),
        ...mapMutations({
            setPageCoverages: 'contents/setPageCoverages',
        }),
        changePages(item) {
            this.setPageCoverages(item)
            this.loadCoverages({
                page: this.page,
            });
            utils.LoaderScrollToTop();
        }
    },
    computed: {
        ...mapGetters({
            getPagesCoverages: 'contents/getPagesCoverages',
            page: 'contents/getCoveragesViewPage',
            theme: 'contents/getCoveragesLastFiltersTheme',
            zone: 'contents/getCoveragesLastFiltersZone'
        }),
        coverages: function () {
            return store.getters['contents/getCoverages'](this.page)
        },
        CoveragesMaxItems() {
            return this.getPagesCoverages();
        }
    },
    mounted() {
        this.loadCoverages();
    },
    watch: {
        filterTime: function (newVal, oldVal) {
            this.loadCoverages();
        }
    }
}
</script>

<style scoped lang="scss">
.reports {
}
</style>