<template lang="html">
    <section class="report-featured" v-if="dataContent">
        <router-link :to="{
        name: 'page-entry',
        params: {
          typeContent: this.dataContent.type,
          slug: this.dataContent.slug,
          typeMedia: this.typeMedia,
        },
      }" class="content-new" title="Enlace a reportaje">
            <img :src="dataContent.image.url" :alt="dataContent.image_footer" class="img-new" />
            <!-- <span class="category">ÚLTIMA ENTREVISTA</span> -->
            <div class="info-box">
                <p class="title">
                    {{ dataContent.name }}
                </p>
                <p class="box-dates">
                    <span class="date-text"> {{ currentDateTime(dataContent.publish_date) }}</span>

                    <span class="author" v-if="dataContent.source">{{dataContent.source}}</span>
                    <template v-for="theme in dataContent.themes" :key="theme.id">
                        <span class="category-tag">{{ theme.name }}</span>
                    </template>
                    <span class="category-location" v-if="dataContent.zone">{{
                        dataContent.zone.name
                        }}</span>
                </p>

                <p class="subtitle">
                    {{ dataContent.header }}
                </p>
            </div>
        </router-link>
    </section>

</template>

<script lang="js">
    import moment from 'moment'
    export default {
        name: 'report-featured',
        props: ['dataContent'],
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {
            currentDateTime(date) {
                return moment(date).locale('es').format('L')
            },
        },
        computed: {
            typeContent() {
                switch (this.dataContent.type) {
                    case 'entry':
                        return 'noticia'
                    case 'opinion':
                        return 'opinion'
                    case 'interview':
                        return 'entrevista'
                    case 'coverage':
                        return 'reportaje'
                    case 'event':
                        return 'evento'
                    case 'media':
                        return 'multimedia'
                    default:
                        return 'noticia'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";

    .report-featured {
        cursor: pointer;

        .content-new {
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            position: relative;
            border-radius: 13px;
            padding-left: 10px;
            margin-bottom: 20px;

            .title {
                color: white;
                font-size: 30px;
            }

            .date-text,
            .author,
            .subtitle {
                color: white;
            }

            .category-location {
                color: white;
                background-image: url(/img/icons/icon-map-white.svg);
            }

            .category-tag {
                color: white;
                background-image: url(/img/icons/icon-tag-white.svg);
            }

            .category {
                position: absolute;
                left: 0px;
                top: 10px;
                z-index: 2;
                background: $blue;
                font-weight: 900;
                color: white;
                font-size: 16px;
                padding: 5px 15px;
                border-radius: 13px;
                z-index: 0;
            }

            .img-new {
                width: 100%;
                border-radius: 13px 13px 0 0;
                height: 325px;
                object-fit: cover;
                object-position: center;
            }

            .info-box {
                width: 100%;
                padding: 20px;
                background: $blue;
                color: white;
                border-radius: 0 0 13px 13px;
            }
        }
    }

    @media screen and (max-width:845px) {
        .report-featured .content-new .title,
        .report-featured .content-new .title p {
            font-size: 22px;
        }

        .report-featured .content-new .subtitle[data-v-f72a5a12]{
            font-size: 17px;
        }

        .content-new .img-new {
            height: 235px;
        }

       .report-featured .content-new{
           padding-left: 0;
       }

        .info-box {
            padding: 10px 0;
        }
    }
   
</style>